import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, ArrowIcon, Tag, ReadMore } from '~styles/global'
import Section from '~components/Section'
import { MapShape } from '~components/Svg'
import Multi from '~components/Multi'
import Image from '~components/Image'
import { motion, AnimatePresence } from 'framer-motion'
import SanityLink from '~components/SanityLink'

const Map = ({ className, content }) => {

	const [selectedMarker, setSelectedMarker] = useState(0)

	if(!content) return null

	return (
		<Wrap className={className}>
			<Top>
				<Title><Multi>{content.title}</Multi></Title>
				<StyledReadMore 
					link={content?.button?.link} 
					className='link'
				>
					<Multi>
						{content?.button?.text}
					</Multi>
					<ArrowIcon />
				</StyledReadMore>
			</Top>
			<Relative>
				{content.pins.map((pin, i) => (
					<Pin 
						key={pin?.title?.english} 
						left={pin?.positionX} 
						top={pin?.positionY}
						onClick={() => setSelectedMarker(i)}
						active={selectedMarker === i}
					>
						<Point className='caption'>{String(i + 1).padStart(2, 0)}</Point>
						<Illustration active={selectedMarker === i} image={pin.illustration}/>
					</Pin>
				))}
				<Continent />
			</Relative>
			<Content>
				{content.pins.map((pin,i) => (
					<Inner key={pin?.title?.english} active={selectedMarker === i}>
						<ContentLeft>
							<SelectedTitle>
								{String(i + 1).padStart(2, 0)}. <Multi>{pin?.title}</Multi>
							</SelectedTitle>
							<Tags>
								{pin.tags?.map(tag => (
									<Tag key={tag.slug?.current} className='tag'><Multi>{tag.title}</Multi></Tag>
								))}
							</Tags>
						</ContentLeft>
						<ContentRight>
							<Text className='sm'>
								<Multi>{pin?.text}</Multi>
							</Text>
						</ContentRight>
					</Inner>
				))}
			</Content>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: var(--beige);
	padding: 91px 0 84px;
	${mobile}{
		padding: 60px 0 30px;
	}
`
const Top = styled.div`
	grid-column: span 12;
	margin-bottom: 151px;
	display: flex;
	justify-content: space-between;
	${mobile}{
		margin-bottom: 223px;
	}
`
const Title = styled.h1`
`
const StyledReadMore = styled(ReadMore)`
	${mobile}{
		display: none;
	}
`
const Relative = styled.div`
	position: relative;
	grid-column: span 12;
	max-width: 1210px;
	margin: 0 auto;
	margin-bottom: 124px;
	width: 100%;
	${mobile}{
		margin-bottom: 175px;
	}
`
const Continent = styled(MapShape)`
	color: var(--lightGreen);
`
const Pin = styled.button`
	position: absolute;
	left: ${ props => props.left}%;
	top: ${ props => props.top}%;
	transform: translate(-50%, -50%);
	background-color: ${ props => props.active ? 'var(--purple)' : 'var(--white)'};
	color: ${ props => props.active ? 'var(--white)' : 'var(--black)'};
	width: 58px;
	height: 58px;
	border-radius: 50px;
	transition: color 0.2s, background-color 0.2s; 
`
const Illustration = styled(Image)`
	width: 217px;
	position: absolute;
	bottom: 58px;
	right: 42px;
	pointer-events: none;
	opacity: ${ props => props.active ? '1' : '0'};
	transition: opacity 0.2s;
`
const Point = styled.div`

`
const Content = styled.div`
	display: grid;
	grid-column: span 12;
`
const Inner = styled.div`
	grid-row: 1;
	grid-column: 1/2;
	opacity: ${ props => props.active ? '1' : '0'};
	pointer-events: ${ props => props.active ? 'all' : 'none'};
	transition: opacity 0.2s;
	display: flex;
	${mobile}{
		flex-wrap: wrap;
	}
`
const ContentLeft = styled.div`
	margin-right: 30px;
`
const SelectedTitle = styled.h2`
  margin-bottom: 24px;
	${mobile}{
		margin-bottom: 23px;
	}
`
const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	${mobile}{
		display: none;
	}
`
const ContentRight = styled.div`
	max-width: 800px;
	margin-left: auto;
`
const Text = styled.div`
`

Map.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}


export default Map