import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import Multi from '~components/Multi'
import HomeSlider from '~components/HomeSlider'
import StatsSection from '~components/StatsSection'
import Map from '~components/Map'
import MapMobile from '~components/MapMobile'
import VerticalScroller from '~components/VerticalScroller'
import MissionStatement from '~components/MissionStatement'
import ProjectsSlider from '~components/ProjectsSlider'
import '~styles/flickity.css'
import { mobile } from '~styles/global'
import { useLang } from '~context/siteContext'

const IndexPage = ({ data }) => {
	const { page, latestResources, latestProjects } = data
	const	[ lang ] = useLang()

	return (
		<>	
			<Seo 
				title={page?.title} 
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<HomeSlider slides={page?.slider}/>
			<StatsSection text={page?.statsText} button={page?.statsButton} stats={page?.stats} />
			<MapDesktop content={page?.map}/>
			<MapMob content={page?.map}/>
			<VerticalScroller showTile title={page.latestResourcesTitle} button={page.viewAllResourcesButton} resources={latestResources?.nodes}/>
			<MissionStatement title={page?.missionStatementHeading} text={page?.missionStatement}/>
			<ProjectsSlider title={page.latestProjectsTitle} button={page?.viewAllProjectsButton} projects={[...latestProjects?.nodes, ...latestProjects?.nodes, ...latestProjects?.nodes, ...latestProjects?.nodes, ...latestProjects?.nodes, ...latestProjects?.nodes]}/>
			{/* <DataViewer data={data} name="data"/> */}
		</>
	)
}

const MapDesktop = styled(Map)`
	${mobile}{
		display: none
	}
`
const MapMob = styled(MapMobile)`
	display: none;
	${mobile}{
		display: block;
	}
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title {
				...multiString
			}
			slider {
				image {
					...imageWithAlt
				}
				imageMobile {
					...imageWithAlt
				}
				headline {
					...multiString
				}
				color {
					value
				}
			}
			statsText {
				...multiText
			}
			statsButton {
				...button
			}
			stats {
				percentage
				text {
					...multiText
				}
			}
			map {
				title {
					...multiString
				}
				genericTitleMobile {
					...multiString
				}
				genericTextMobile {
					...multiText
				}
				button {
					...button
				}
				pins {
					title {
						...multiString
					}
					text {
						...multiText
					}
					tags {
						title {
						...multiString
						}
						slug {
							current
						}
					}
					positionX
					positionY
					illustration {
						...imageWithAlt
					}
				}
			}
			latestResourcesTitle {
				...multiString
			}
			viewAllResourcesButton {
				...button
			}
			missionStatementHeading {
				...multiString
			}
			missionStatement {
				...multiText
			}
			latestProjectsTitle {
				...multiString
			}
			viewAllProjectsButton {
				...button
			}
			seo{
				...seo
			}
    }
		latestResources : allSanityResource(sort: {fields: datePublished, order: DESC}, limit: 6) {
			nodes {
				...resourceThumb
			}
		}
		latestProjects : allSanityProject(sort: {fields: datePublished, order: DESC}, limit: 6) {
			nodes {
				...projectThumb
			}
		}
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
