import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Multi from '~components/Multi'
import Section from '~components/Section'

const MissionStatement = ({ className, title, text }) => {
	return (
		<Wrap className={className}>
			<Inner>
				<Statement>
					<Heading className='headline'><Multi>{title}</Multi></Heading>
					<Text><Multi>{text}</Multi></Text>
				</Statement>
			</Inner>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	>div{ height: 100vh; }
	background-color: var(--beige);
`
const Inner = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	grid-column: span 12;
	padding: 0 20px;
`
const Statement = styled.div`
	text-align: center;
	max-width: 1322px;
	margin: 0 auto;
`
const Heading = styled.div`
	margin-bottom: 72px;
	${mobile}{
		margin-bottom: 32px;
	}
`
const Text = styled.div`
	
`

MissionStatement.propTypes = {
	className: PropTypes.string,
	title: PropTypes.object,
	text: PropTypes.object,
}

export default MissionStatement